import React from "react";
import DateCounter from "./DateCounter";
import AnimatedCursor from "react-animated-cursor";
function CommingSoonPage() {
  return (
    <>
      <AnimatedCursor
        innerSize={8}
        outerSize={15}
        color="217, 10, 44"
        outerAlpha={0.5}
        innerScale={0.7}
        outerScale={5}
      />
      <section className="comming-soon">
        <div className="comming-soon-left">
          <div className="cngs-content">
            <h1>Bientot/Coming Soon</h1>
            <p>
             QLifeGames Lancera sont SiteWeb Officel en 2023 / QLifeGames will launch is Official website in 2023
            </p>
            <DateCounter />
            <h1>Email: info@qlifegames.ca</h1>
          {/*   <div className="subscribe-mail">
              <form
                onSubmit={(e) => e.preventDefault()}
                action="#"
                method="post"
              >
                <input type="email" name="email" placeholder="Email Address" />
                <input type="submit" defaultValue="Send Now" />
              </form>
            </div> */}
            <ul className="social-icons">
              <li>
                <a href="https://www.facebook.com/QLifeGames">
                  <i className="fab fa-facebook-f" />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/QLifeGames">
                  <i className="fab fa-twitter" />
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/channel/UCEKzZo4XvMRyBnLKkhXdyWQ">
                  <i className="fab fa-youtube" />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/kathouqc/">
                  <i className="fab fa-instagram" />
                </a>
              </li>
              <li>
                <a href="https://discord.gg/82dyFJPCVa">
                  <i className="fab fa-discord" />
                </a>
              </li>
            </ul>
          </div>
        </div>
{/*         <div className="comming-soon-right"> */}
          <img
            src={process.env.PUBLIC_URL + "/images/logo-original-1000.png"}
            alt="images"
          />
    {/*     </div> */}
      </section>
    </>
  );
}

export default CommingSoonPage;
